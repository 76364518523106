<template>
  <div class="container-fluid p-3">
    <b-row>
      <!-- Form Section - Left Column -->
      <b-col md="5">
        <b-card class="h-100"
                no-body
        >
          <b-card-body class="p-2">
            <!-- Initial Events -->
            <div class="mb-2">
              <label class="small fw-bold mb-1">Initial Events</label>
              <json-editor
                v-model="initialEvents"
                :options="jsonEditorOptions"
                class="json-editor-container"
              />
            </div>

            <!-- Schedules -->
            <div>
              <div class="d-flex justify-content-between align-items-center mb-1">
                <label class="small fw-bold mb-0">Schedules</label>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="addSchedule"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>

              <div class="schedules-container">
                <div v-for="(schedule, index) in schedules"
                     :key="index"
                     class="mb-1"
                >
                  <b-row class="g-1">
                    <b-col md="5">
                      <div class="position-relative">
                        <b-form-datepicker
                          v-model="schedule.date"
                          size="sm"
                          class="small-datepicker"
                          menu-class="datepicker-menu"
                        />
                      </div>
                    </b-col>
                    <b-col>
                      <b-form-input
                        v-model="schedule.lesson"
                        placeholder="Lesson"
                        size="sm"
                      />
                    </b-col>
                    <b-col cols="auto">
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        @click="removeSchedule(index)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>

            <!-- Submit Button -->
            <div class="mt-3 d-flex justify-content-end">
              <b-button
                variant="primary"
                size="sm"
                :disabled="isSubmitting"
                @click="handleSubmit"
              >
                <b-icon icon="check2"
                        class="me-1"
                />
                {{ isSubmitting ? 'Submitting...' : 'Submit' }}
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Result UI Section - Right Column -->
      <b-col md="7">
        <b-card class="h-100"
                no-body
        >
          <b-card-header class="py-2">
            <h5 class="mb-0">
              Results
            </h5>
          </b-card-header>

          <b-card-body class="p-2">
            <!-- Scheduled Preview Lessons -->
            <div class="mb-3">
              <label class="small fw-bold mb-1">Scheduled Preview Lessons</label>
              <b-table
                striped
                hover
                small
                bordered
                :items="previewLessons"
                :fields="lessonFields"
                class="mb-0"
              />
            </div>

            <!-- Review Lessons -->
            <div>
              <label class="small fw-bold mb-1">Review Lessons</label>
              <b-table
                striped
                hover
                small
                bordered
                :items="reviewLessons"
                :fields="lessonFields"
                class="mb-0"
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import JsonEditor from 'json-editor-vue'
import {
  BCard,
  BCardHeader,
  BCardBody,
  BFormDatepicker,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BTable,
  BIcon,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'EventForm',
  components: {
    JsonEditor,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BTable,
    BIcon,
  },
  data() {
    return {
      initialEvents: [
        {
          start_time: '2025-02-01',
          end_time: '2025-02-03',
          lesson: 1,
        },
        {
          start_time: '2025-02-04',
          end_time: '2025-02-06',
          lesson: 2,
        },
        {
          start_time: '2025-02-07',
          end_time: '2025-02-09',
          lesson: 3,
        },
        {
          start_time: '2025-02-10',
          end_time: '2025-02-12',
          lesson: 4,
        },
        {
          start_time: '2025-02-13',
          end_time: '2025-02-15',
          lesson: 5,
        },
        {
          start_time: '2025-02-16',
          end_time: '2025-02-18',
          lesson: 6,
        },
        {
          start_time: '2025-02-19',
          end_time: '2025-02-21',
          lesson: 7,
        },
        {
          start_time: '2025-02-22',
          end_time: '2025-02-24',
          lesson: 8,
        },
        {
          start_time: '2025-02-25',
          end_time: '2025-02-27',
          lesson: 9,
        },
        {
          start_time: '2025-02-28',
          end_time: '2025-03-02',
          lesson: 10,
        },
      ],
      schedules: [],
      isSubmitting: false,
      jsonEditorOptions: {
        mode: 'code',
        mainMenuBar: false,
        statusBar: false,
        navigationBar: false,
      },
      lessonFields: [
        { key: 'lesson', label: 'Lesson' },
        { key: 'start_time', label: 'Start Time' },
        { key: 'end_time', label: 'End Time' },
      ],
      previewLessons: [
      ],
      reviewLessons: [
      ],
    }
  },
  methods: {
    addSchedule() {
      this.schedules.push({
        date: '',
        lesson: '',
      })
    },
    removeSchedule(index) {
      this.schedules.splice(index, 1)
    },
    async handleSubmit() {
      this.isSubmitting = true
      useJwt.checkPartnerLaSchedule({
        initialEvents: this.initialEvents,
        schedules: this.schedules,
      }).then(response => {
        this.previewLessons = response.data.data.preview
        this.reviewLessons = response.data.data.review
        console.log(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isSubmitting = false
      })
    //   try {
    //     this.isSubmitting = true
    //     // Add your submit logic here
    //     await new Promise(resolve => setTimeout(resolve, 1000)) // Simulating API call
    //     // Handle success
    //     console.log('Submitted:', {
    //       initialEvents: this.initialEvents,
    //       schedules: this.schedules,
    //     })
    //   } catch (error) {
    //     console.error('Submit error:', error)
    //   } finally {
    //     this.isSubmitting = false
    //   }
    },
  },
}
</script>

  <style scoped>
  .json-editor-container {
    height: 700px;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
  }

  /* Fix for datepicker overlay */
  ::v-deep .datepicker-menu {
    z-index: 3000 !important;
  }

  /* Custom styling for datepicker to make it compact */
  ::v-deep .small-datepicker .b-form-datepicker {
    font-size: 0.875rem;
  }

  /* Compact table styles */
  ::v-deep .table {
    font-size: 0.875rem;
  }

  ::v-deep .table td,
  ::v-deep .table th {
    padding: 0.3rem 0.5rem;
  }

  /* Card header styling */
  ::v-deep .card-header {
    background-color: #f8f9fa;
  }

  /* Make form controls more compact */
  ::v-deep .form-control {
    font-size: 0.875rem;
  }

  /* Custom scrollbar for schedules container */
  .schedules-container::-webkit-scrollbar {
    width: 6px;
  }

  .schedules-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .schedules-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  .schedules-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  </style>
